<template>
  <b-container fluid class="member-page">
    <b-row>
      <b-col md="4" cols="12">
        <div class="card">
          <div class="card-header min-h-105">
            <h3>{{ Statistical.total_member }}</h3>
            <p>Member</p>
          </div>
          <div class="card-body">
            <b-form-group label="Your wallet">
              <b-input-group>
                <b-form-input
                  :value="UserInfo.address ? UserInfo.address : 'Not Update'"
                  readonly
                ></b-form-input>
                <b-input-group-append v-if="UserInfo.address">
                  <b-button
                    variant="info"
                    v-clipboard:copy="UserInfo.address"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    Copy
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Referral Link">
              <b-input-group>
                <b-form-input :value="url" readonly></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="info"
                    v-clipboard:copy="url"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    Copy
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col md="8" cols="12" class="mx-auto">
        <div class="card">
          <div class="card-header text-left min-h-105">
            <h3 class="pt-20">Partner</h3>
          </div>
          <div class="card-body">
            <h3></h3>
            <div class="row-5">
              <div class="col" v-for="(key, value) in Statistical.children" :key="key + value">
                <div class="box">
                  <h4>{{ key }}</h4>
                  <p>{{ value }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <div class="card">
          <div class="card-header py-3 text-left">
            <h3 class="mb-0 fz-md">Member List</h3>
          </div>
          <div class="card-body">
            <div class="table-search">
              <b-form-input
                size="sm m-1"
                placeholder="User ID"
                v-model.trim="search.user_id"
              ></b-form-input>
              <b-form-input
                size="sm m-1"
                placeholder="Email"
                v-model.trim="search.email"
              ></b-form-input>
              <b-form-input
                size="sm m-1"
                placeholder="F.."
                v-model.trim="search.user_f"
              ></b-form-input>
              <b-button variant="info" class="btn-sm m-1" @click="onSearch"> Search </b-button>
              <b-button variant="warning" class="btn-sm m-1" @click="onSearch('reset')">
                Reset
              </b-button>
            </div>
            <div class="table-reponsive">
              <b-table striped hover :items="Member.list" :fields="fields">
                <template #cell(f)="data"> F{{ data.item.f }} </template>
                <template #cell(email)="data">
                  {{ data.item.email ? data.item.email : 'Not Update' }}
                </template>
                <template #cell(User_WalletAddress)="data">
                  {{ data.item.User_WalletAddress ? data.item.User_WalletAddress : 'Not Update' }}
                </template>
                <template #cell(User_WalletAddressTron)="data">
                  {{ data.item.User_WalletAddressTron ? data.item.User_WalletAddressTron : 'Not Update' }}
                </template>
              </b-table>
            </div>
            <div class="table-paginate">
              <Paginate @current="onChangePage" :totalPages="Member.total" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import Paginate from '@/components/shared/Paginate.vue';

export default {
  components: { Paginate },
  data() {
    return {
      search: {
        user_id: '',
        email: '',
        user_f: '',
        page: 1,
      },
      partners: [
        { f: 1, amount: 10 },
        { f: 2, amount: 20 },
        { f: 3, amount: 30 },
        { f: 4, amount: 60 },
        { f: 5, amount: 10 },
      ],
      fields: [
        {
          key: 'userId',
          sortable: true,
          label: 'User ID',
        },
        {
          key: 'email',
          sortable: true,
          label: 'User Email',
        },
        {
          key: 'f',
          label: 'Ref',
          sortable: true,
        },
        {
          key: 'activebot',
          label: 'Active Bot',
          sortable: false,
        },
        {
          key: 'tree',
          label: 'Tree',
          sortable: true,
        },
        {
          key: 'User_WalletAddress',
          sortable: true,
          label: 'Address Metamask',
        },
        {
          key: 'User_WalletAddressTron',
          sortable: true,
          label: 'Address Tron',
        },
        {
          key: 'totalDeposit',
          label: 'Total Deposit',
          sortable: true,
        },
        {
          key: 'totalWithdraw',
          label: 'Total Withdraw',
          sortable: true,
        },
        {
          key: 'totalactiveBotCommis',
          label: 'Total Active Bot Commission',
          sortable: true,
        },
        {
          key: 'totalCommission',
          label: 'Total Commission',
          sortable: true,
        },
        {
          key: 'totalTrade',
          label: 'Total Trade',
          sortable: true,
        },
        {
          key: 'registeredDatetime',
          label: 'Registration Date',
        },
      ],
      currentPage: 1,
      totalPages: 10,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      Member: 'member/Member',
      Statistical: 'member/Statistical',
      Referral: 'member/Referral',
    }),
    url() {
      return `${window.location.host}/secure/register?sponsor=${this.UserInfo.id}`;
    },
  },
  methods: {
    onCopy() {
      this.$toastr.s('Copy Success', 'Successfully');
    },
    onError() {
      this.$toastr.e('Copy Fail,Please Try Again', 'Failed');
    },
    onSearch(type) {
      if (type === 'reset') {
        this.search = {
          user_id: '',
          email: '',
          user_f: '',
          page: 1,
        };
      }
      this.$store.dispatch('member/req_getListMember', this.search);
    },
    onChangePage(payload) {
      this.search.page = payload;
      this.$store.dispatch('member/req_getListMember', this.search);
    },
  },
  created() {
    this.$store.dispatch('member/req_getListMember', this.search);
    this.$store.dispatch('member/req_getStatistical');
  },
};
</script>

<style lang="scss">
.row-5 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
  padding: 20px 0;
  @media (max-width: 575px) {
    justify-content: center;
  }
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 8px;
    padding-left: 8px;
    @media (max-width: 575px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box {
      margin-top: 10px;
      text-align: center;
      border: 1px #fff solid;
      border-radius: 10px;
      padding: 20px;
      h4 {
        font-size: 28px;
        font-weight: bold;
        color: #ffc107;
        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 18px;
        text-transform: uppercase;
        @media (max-width: 992px) {
          font-size: 16px;
        }
      }
    }
  }
}
.pt--20 {
  padding-top: 20px !important;
}
.min-h-105 {
  min-height: 105px;
}
.table-reponsive {
  overflow-y: auto;
}
.btn-info {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
}
.btn-info:hover {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
  box-shadow: 0 0px 20px #0c83e9;
}
.member-page {
  .Referral-card {
    .ref-id {
      h4 {
        margin-bottom: 8px;
        text-transform: capitalize;
        letter-spacing: 0.02em;
        font-size: 18px;
        font-weight: 600;
        margin-top: 0;
        color: #6c757d;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
        span {
          font-size: 22px;
          color: #2f394e;
          font-weight: 600;
        }
      }
    }
    .boxref {
      width: 100%;
      span {
        font-size: 17px;
        color: #6c757d;
        font-weight: 600;
      }
      .ref-link {
        margin-top: 5px;
        background: #009750;
        border-radius: 5px;
        .input-group-text {
          padding: 0;
          border: 0;
          button {
            width: 70px;
            height: 40px;
            padding: 0;
            background: #8dc63f;
            border: 0;
            outline: none;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 1px #00000042;
            font-weight: 600;
          }
        }
        input {
          height: 40px;
          border: 0;
          background: transparent;
          color: #fff;
          font-weight: 600;
          &:hover,
          &:focus,
          &:focus-visible {
            outline: none;
            box-shadow: none;
            border: 0;
          }
        }
      }
    }
  }
  .balance-card,
  .pnl-card {
    .box-info-content {
      width: 100%;
      height: 100%;
      align-items: center;
      .box-right,
      .box-left {
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        .cash {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2f394e !important;
          font-weight: 600;
          font-size: clamp(16px, 2vw, 20px);
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6c757d;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(15px, 2vw, 17px);
          text-transform: capitalize;
        }
      }
      .box-left {
      }
      .box-right {
      }
      span.level-name {
        margin-bottom: 0px;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: 15px;
        font-weight: 700;
        margin-top: 0;
        color: #6c757d;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
      }
      .img-level {
        width: 75px;
        height: 75px;
        padding: 0px;
        background: transparent;
        box-shadow: none;
        filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.5));
        // border: 1px solid #34a849;
      }
    }
  }
  .box-member {
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    background-color: #fff;
    min-height: 200px;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%;
      padding: 5px 1.25rem;
    }
    .table-search {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.08);
      text-transform: uppercase;
      width: 100%;
      min-height: 50px;
      padding: 5px 1.25rem;
      display: flex;
      flex-wrap: wrap;
      input {
        max-width: 225px;
      }
      select,
      input {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #6c757d;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        position: relative;
        &:focus {
          box-shadow: none;
          border-bottom: 2px solid #097501;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
      button {
        &.btn-egg {
          min-width: 105px;
          height: 40px;
        }
        width: auto;
        min-width: unset;
        font-weight: 600;
      }
    }
    .table-reponsive {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px 5px;
      table {
        width: 100%;
        border-radius: 10px;
        th,
        td {
          min-width: 100px;
          white-space: pre;
          padding: 5px 0.75rem;
        }
        thead {
          background: #009750;
          border-radius: 10px;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
          border: 0;
          color: #fff;
          font-weight: 600;
          tr {
            border-radius: 10px;
            th {
              border: 0;
              &:last-child {
                border-radius: 0px 10px 10px 0px;
              }
              &:first-child {
                border-radius: 10px 0 0 10px;
              }
            }
          }
        }
        tbody:before {
          content: '@';
          display: block;
          line-height: 10px;
          text-indent: -99999px;
        }
      }
    }
    .table-paginate {
      border-top: 1px solid rgba($color: #0c80e4, $alpha: 0.08);
    }
  }
}
</style>
